export const namespaced = true 
export default {
    state : {
        error :'',
        errors :[],
        apierror:'',
        msg :'',
        loading:'',
        pageloader:'',
        logintypetype:'',
        uploadPercentage:0
    },
    
    getters : {
        error: (state )=> state.error,
        errors: (state )=> state.errors,
        apierror: (state )=> state.apierror,
        msg: (state )=> state.msg,
        loading: (state )=> state.loading,
        pageloader: (state )=> state.pageloader,
        logintypetype: (state )=> state.logintypetype,
        uploadPercentage: (state )=> state.uploadPercentage,
    },

    

    mutations : { 
        setloader :(state,loading ) => (state.loading = loading), 
        setpageloader :(state,pageloader ) => (state.pageloader = pageloader), 
        seterror :(state,error ) => (state.error = error), 
        seterrors :(state,errors ) => (state.errors = errors), 
        setmsg :(state,msg ) => (state.msg = msg), 
        setapierror :(state,apierror ) => (state.apierror = apierror),
        setlogintypetype :(state,logintypetype ) => (state.logintypetype = logintypetype),
        setuploadPercentage :(state,uploadPercentage ) => (state.uploadPercentage = uploadPercentage),
    },


    actions : {

        async clearutils( { commit }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', '')
            commit('setapierror', '')
            commit('seterrors', '')
            commit('setpageloader', '')
        },


        setlogintypetype({ commit }, type){
            commit('setlogintypetype', type)
           },

    },

    
}