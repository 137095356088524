export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        dishes :'', 
        dish :'',
    },
    
    getters : {
        dishes: (state )=> state.dishes,
        dish: (state )=> state.dish,
       
    },


    mutations : { 
        setdishes :(state,dishes ) => (state.dishes = dishes), 
        setdish :(state,dish ) => (state.dish = dish),
    },

    actions : {
        // ==================================================== dishes ===============================================
        async create_dish( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_dish')
            return new Promise((resolve, reject) => {
            axios.post("/admin/dishes/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdishes', response.data.res)
                toast.success("dish added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_dishes( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_dishes')
            return new Promise((resolve, reject) => {
            axios.post("/admin/dishes?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdishes', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_dish( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_dish' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/dishes/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdishes', response.data.res)
                toast.success("dish updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_dish_image( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_dish_image' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/dishes/update_image/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdishes', response.data.res)
                toast.success("dish's image updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        
        async delete_dish( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_dish' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/dishes/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdishes', response.data.res)
                toast.success("dish deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}