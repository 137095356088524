let auth_routes = [{
    path: "/auth",
    name: 'auth',
    props: true,
    component: () => import("@/views/auth/main.vue"),
    children: [
      // ============================================== Dashboard ============================================
      {
        path: "login",
        name: 'login',
        props: true,
        component: () => import("@/views/auth/login.vue"),
        title: 'Sign In',
      },

      {
        path: "register",
        name: 'register',
        props: true,
        component: () => import("@/views/auth/register.vue"),
        title: 'Sign Up',
      },
    ]
}];

export default auth_routes;