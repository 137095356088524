import store from '@/store'

const adminguard = (to, from, next) => {
    if(localStorage.getItem('menuauthtoken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.id ){
        return next()
    }else{
        return next({ name: 'login' })
    }
};


let routes = [{
  path: "/",
  props: true,
  beforeEnter: adminguard,
  component: () => import("@/views/app/main.vue"),
  children: [
    // ============================================== Sales ============================================
    {
      path: "/",
      name: 'sales',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/pages/sales.vue"),
      meta: {
        title: 'Daily General Sales',
      },
    },

    {
      path: "sales/create_sales",
      name: 'create_sales',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/pages/create_sales.vue"),
      meta: {
        title: 'Create Sales',
      },
    },

    {
      path: "/dishes",
      name: 'dishes',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/pages/dishes.vue"),
      meta: {
        title: 'All Dishes',
      },
    },

    {
      path: "/expenses",
      name: 'expenses',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/pages/expenses.vue"),
      meta: {
        title: 'All Expenses',
      },
    },

    {
      path: "/reports/",
      name: 'reports',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/pages/reports/main.vue"),
      children: [
        {
          path: "",
          name: 'expenses_reports',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/pages/reports/expenses_report.vue"),
          meta: {
            title: 'Expenses Report',
          },
        },

        {
          path: "sales",
          name: 'sales_reports',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/pages/reports/sales_report.vue"),
          meta: {
            title: 'Sales Report',
          },
        },
      ]
    },

    {
      path: "/profile",
      name: 'profile',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/pages/profile/main.vue"),
      meta: {
        title: 'Profile',
      },
    },
  ]
}]
export default routes;